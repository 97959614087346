import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CheckoutForm from "../../components/checkout/CheckoutForm"

const Checkout = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <CheckoutForm />
    </Layout>
  )
}

export default Checkout
