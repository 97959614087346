export const countries = [{ value: "canada", name: "Canada" }]

export const provinces = [
  { value: "alberta", name: "Alberta" },
  { value: "british_columbia", name: "British Columbia" },
  { value: "manitoba", name: "Manitoba" },
  { value: "new_brunswick", name: "New Brunswick" },
  {
    value: "newfoundland_and_labrabor",
    name: "Newfoundland and Labrador",
  },
  {
    value: "northwest_territories",
    name: "Northwest Territories",
  },
  { value: "nova_scotia", name: "Nova Scotia" },
  { value: "nunavut", name: "Nunavut" },
  { value: "ontario", name: "Ontario" },
  {
    value: "prince_edward_island",
    name: "Prince Edward Island",
  },
  { value: "quebec", name: "Quebec" },
  { value: "saskatchewan", name: "Saskatchewan" },
  { value: "yukon_territory", name: "Yukon Territory" },
]

export const unitedStates = [
  { value: "Alabama", name: "Alabama" },
  { value: "Alaska", name: "Alaska" },
  { value: "Arizona", name: "Arizona" },
  { value: "Arkansas", name: "Arkansas" },
  { value: "California", name: "California" },
  { value: "Colorado", name: "Colorado" },
  { value: "Connecticut", name: "Connecticut" },
  { value: "Delaware", name: "Delaware" },
  {
    value: "District_of_Columbia",
    name: "District of Columbia",
  },
  { value: "Florida", name: "Florida" },
  { value: "Georgia", name: "Georgia" },
  { value: "Hawaii", name: "Hawaii" },
  { value: "Idaho", name: "Idaho" },
  { value: "Illinois", name: "Illinois" },
  { value: "Indiana", name: "Indiana" },
  { value: "Iowa", name: "Iowa" },
  { value: "Kansas", name: "Kansas" },
  { value: "Kentucky", name: "Kentucky" },
  { value: "Louisiana", name: "Louisiana" },
  { value: "Maine", name: "Maine" },
  { value: "Maryland", name: "Maryland" },
  { value: "Massachusetts", name: "Massachusetts" },
  { value: "Michigan", name: "Michigan" },
  { value: "Minnesota", name: "Minnesota" },
  { value: "Mississippi", name: "Mississippi" },
  { value: "Missouri", name: "Missouri" },
  { value: "Montana", name: "Montana" },
  { value: "Nebraska", name: "Nebraska" },
  { value: "Nevada", name: "Nevada" },
  { value: "New_Hampshire", name: "New Hampshire" },
  { value: "New_Jersey", name: "New Jersey" },
  { value: "New_Mexico", name: "New Mexico" },
  { value: "New_York", name: "New York" },
  { value: "North_Carolina", name: "North Carolina" },
  { value: "North_Dakota", name: "North Dakota" },
  { value: "Ohio", name: "Ohio" },
  { value: "Oklahoma", name: "Oklahoma" },
  { value: "Oregon", name: "Oregon" },
  { value: "Pennsylvania", name: "Pennsylvania" },
  { value: "Rhode_Island", name: "Rhode Island" },
  { value: "South_Carolina", name: "South Carolina" },
  { value: "South_Dakota", name: "South Dakota" },
  { value: "Tennessee", name: "Tennessee" },
  { value: "Texas", name: "Texas" },
  { value: "Utah", name: "Utah" },
  { value: "Vermont", name: "Vermont" },
  { value: "Virginia", name: "Virginia" },
  { value: "Washington", name: "Washington" },
  { value: "West Virginia", name: "West Virginia" },
  { value: "Wisconsin", name: "Wisconsin" },
  { value: "Wyoming", name: "Wyoming" },
]
