import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import styled from "styled-components"
import { navigate } from "gatsby"

import { CartContext } from "../../context/CartContext"
import { UiContext } from "../../context/UiContext"
import { RatesContext } from "../../context/RatesContext"
import {
  cartTotal,
  priceInDollar,
  cartSubtotal,
} from "../../Utilities/cart/cart"
import { updateShippingCosts } from "../../Utilities/cart/rates"

import CartTotalTable from "../cart/CartTotalTable"
import GiftCard from "../cart/GiftCard"
import Input from "./Input"
import DropDown from "./DropDown"
import {
  headlineThree,
  standardWrapper,
  colors,
  buttonOne,
  bodyCopy,
} from "../../Utilities"
import { countries, provinces, unitedStates } from "./formData"

import {
  validateNameField,
  validateEmailField,
  validateEmailMatchField,
  validateTextField,
} from "../../Utilities/form/formValidation"

import { confirmTotal } from "../../Utilities/cart/cart"

const CheckoutForm = () => {
  const { cart } = useContext(CartContext)
  const [rateState, rateDispatch] = useContext(RatesContext)
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const [disablePaypal, setDisablePaypal] = useState(false)

  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    emailconfirm: "",
    country: rateState.activeLocation ? rateState.activeLocation : "canada",
    province: rateState.activeProvince ? rateState.activeProvince : "alberta",
    address: "",
    city: "",
    postalcode: "",
    phone: "",
    formValid: [
      { fieldName: "name", valid: true, message: "" },
      { fieldName: "lastname", valid: true, message: "" },
      { fieldName: "email", valid: true, message: "" },
      { fieldName: "emailconfirm", valid: true, message: "" },
      { fieldName: "country", valid: true, message: "" },
      { fieldName: "province", valid: true, message: "" },
      { fieldName: "address", valid: true, message: "" },
      { fieldName: "city", valid: true, message: "" },
      { fieldName: "postalcode", valid: true, message: "" },
    ],
  })
  /**
   *
   * @param {object} event
   */
  const handleOnChange = (event) => {
    const formValuesCopy = formValues
    const fieldIndex = formValues.formValid.findIndex((field) => {
      return field.fieldName === event.target.name
    })

    if (fieldIndex !== -1 && !formValues.formValid[fieldIndex].valid) {
      formValuesCopy.formValid[fieldIndex] = {
        fieldName: event.target.name,
        valid: true,
        message: "",
      }
    }

    setFormValues({
      ...formValuesCopy,
      [event.target.name]: event.target.value,
    })

    if (event.target.name === "country") {
      rateDispatch({
        type: "LOCATION_CHANGE",
        payload: {
          activeLocation: event.target.value,
        },
      })
    }

    if (event.target.name === "province") {
      rateDispatch({
        type: "PROVINCE_CHANGE",
        payload: {
          activeProvince: event.target.value,
        },
      })
    }
  }
  /**
   * @desc This will change the shipping and tax rates context based on the user selected value from form dropdown.
   * @param {string} location users new location based on the form dropdown selected value.
   */
  useEffect(() => {
    checkShippingCost()
  }, [rateState.activeLocation, rateState.activeProvince])

  const checkShippingCost = async () => {
    uiDispatch({ type: "SERVER_LOAD" })
    let shippingRate
    try {
      const reponse = await axios.get(
        `${process.env.GATSBY_API_URL}/shop-rates`
      )

      const freeShippingThreshold = reponse.data.free_shipping_threshold * 100
      const cartSubTotalInCents = cartSubtotal(cart)

      const SHIPPING_REQUIRED = cart.some((item) => item.should_charge_shipping)

      if (!SHIPPING_REQUIRED) {
        shippingRate = 0
      } else {
        shippingRate = updateShippingCosts(
          rateState.activeLocation,
          rateState.activeProvince,
          reponse.data,
          cartSubTotalInCents
        )
      }
      rateDispatch({
        type: "RATE_CHANGE",
        payload: {
          activeShipping: !SHIPPING_REQUIRED ? 0 : shippingRate,
          freeShipping: !SHIPPING_REQUIRED
            ? true
            : cartSubTotalInCents >= freeShippingThreshold,
        },
      })
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
    } catch (err) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({ type: "ALERT_ERROR", payload: err.response.data.message })
    }

    const priceTotalLocalCart = priceInDollar(
      cartTotal(cart, shippingRate, rateState.activeTax, rateState.giftCard)
    )

    // If there is nothing in the cart, redirect back to cart page. //
    if (cart && cart.length === 0) {
      navigate("/shop", { replace: true })
      return
    }

    // send details to the server to get the total price based on server item data. //
    const confirmedTotal = await confirmTotal(
      cart,
      uiDispatch,
      rateState.activeLocation,
      rateState.activeProvince,
      rateState.giftCardId
    )

    // If null comes back from the server, it means the local cart was empty. Disable the form. //
    if (confirmedTotal === null) {
      setDisablePaypal(true)
      return
    }
    if (confirmedTotal !== priceTotalLocalCart) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({
        type: "ALERT_ERROR",
        payload:
          "Pricing on the server does not match what is in the local cart. Please try again later, or contact the admin.",
      })
      setDisablePaypal(true)
    }
    // we can set the form to active now that everything is confirmed. //
    setDisablePaypal(false)
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()

    // Validate the form entries before we send to the server. //
    const formValidCopy = formValues.formValid
    let stateErrorCheck
    stateErrorCheck = validateNameField(formValidCopy, "name", formValues.name)
    stateErrorCheck = validateNameField(
      formValidCopy,
      "lastname",
      formValues.lastname
    )
    stateErrorCheck = validateEmailField(
      formValidCopy,
      "email",
      formValues.email
    )
    stateErrorCheck = validateEmailMatchField(
      formValidCopy,
      "emailconfirm",
      formValues.email,
      formValues.emailconfirm
    )

    stateErrorCheck = validateTextField(
      formValidCopy,
      "country",
      formValues.country
    )
    stateErrorCheck = validateTextField(
      formValidCopy,
      "province",
      formValues.province
    )
    stateErrorCheck = validateTextField(
      formValidCopy,
      "address",
      formValues.address
    )
    stateErrorCheck = validateTextField(formValidCopy, "city", formValues.city)
    stateErrorCheck = validateTextField(
      formValidCopy,
      "postalcode",
      formValues.postalcode
    )
    setFormValues({ ...formValues, formValid: [...stateErrorCheck] })
    const isFormValid = formValues.formValid.every((field) => field.valid)

    // Make sure the form is valid, if not lets exit out of here! //
    if (!isFormValid) {
      return uiDispatch({
        type: "ALERT_ERROR",
        payload:
          "You have some form fields not correctly filled out. Please adjust fields highlighted in red and try again. Thank you!",
      })
    }
    uiDispatch({ type: "SERVER_LOAD" })

    try {
      const reponse = await axios.post(
        `${process.env.GATSBY_API_URL}/orders/paypal`,
        { cart, formValues, giftCardId: rateState.giftCardId }
      )
      if (reponse.data.link.href) {
        window.location = reponse.data.link.href
      }
    } catch (err) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({
        type: "ALERT_ERROR",
        payload: `${err.message}`,
      })
    }
  }

  return (
    <CheckoutFormStyled>
      <div className="formHeader">
        <h2>Checkout</h2>
      </div>
      <div className="wrapper">
        <div className="giftCardCheck">
          <h3>Have a Gift Card?</h3>
          <GiftCard />
        </div>

        <div>
          <h3>Shipping Information</h3>
        </div>
        <div className="formContainer">
          <form onSubmit={(event) => handleOnSubmit(event)} method="post">
            <fieldset disabled={disablePaypal}>
              <Input
                label="First Name"
                name="name"
                type="text"
                placeholder="Your First Name"
                value={formValues.name}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <Input
                label="Last Name"
                name="lastname"
                type="text"
                placeholder="Your Last Name"
                value={formValues.lastname}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <Input
                label="Email"
                name="email"
                type="text"
                placeholder="Your Email"
                value={formValues.email}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <Input
                label="Confirm Email"
                name="emailconfirm"
                type="text"
                placeholder="Confirm Email"
                value={formValues.emailconfirm}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <DropDown
                label="Your Country"
                name="country"
                value={formValues.country}
                onChange={handleOnChange}
                options={countries}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              {formValues.country === "usa" ? (
                <DropDown
                  label="State"
                  name="province"
                  value={formValues.province}
                  onChange={handleOnChange}
                  options={unitedStates}
                  fieldvalid={formValues.formValid}
                  size="half"
                  required={true}
                />
              ) : (
                <DropDown
                  label="Province"
                  name="province"
                  value={formValues.province}
                  onChange={handleOnChange}
                  options={provinces}
                  error={formValues.formValid}
                  fieldvalid={formValues.formValid}
                  size="half"
                  required={true}
                />
              )}
              <Input
                label="Street Address"
                name="address"
                type="text"
                placeholder="House Number and Street Name"
                value={formValues.address}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="full"
                required={true}
              />
              <Input
                label="Town/City"
                name="city"
                type="text"
                placeholder="Town/City"
                value={formValues.city}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <Input
                label={
                  formValues.country === "usa" ? "Zip Code" : "Postal Code"
                }
                name="postalcode"
                type="text"
                placeholder={
                  formValues.country === "usa" ? "Zip Code" : "Postal Code"
                }
                value={formValues.postalcode}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
                required={true}
              />
              <Input
                label="Phone Number"
                name="phone"
                type="text"
                placeholder="Phone Number"
                value={formValues.phone}
                onChange={handleOnChange}
                fieldvalid={formValues.formValid}
                size="half"
              />

              <CartTotalTable cart={cart} curpage="checkout" />
              <div className="placeOrder">
                <p>
                  Pay via PayPal; you can pay with your credit card if you don’t
                  have a PayPal account.{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.paypal.com/ca/for-you/mobile-wallet/payment-methods"
                  >
                    What is PayPal?
                  </a>
                </p>
                <button type="submit">Proceed to PayPal</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </CheckoutFormStyled>
  )
}

const CheckoutFormStyled = styled.div`
  .formHeader {
    padding: 1.5rem 3rem;
    background-color: ${colors.colorTertiary};
    text-align: center;

    h2 {
      ${headlineThree};
      color: ${colors.white};
      text-transform: uppercase;
    }
  }

  .giftCardCheck {
    width: 100%;
    max-width: 75rem;
    margin: 2rem auto 5rem;

    h3 {
      ${headlineThree};
      text-transform: uppercase;
      text-align: center;
    }
  }

  .wrapper {
    ${standardWrapper};
  }

  .formContainer {
    width: 100%;
    max-width: 75rem;
    margin: 2rem auto 5rem;

    form {
      width: 100%;

      fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        border: none;
        outline: none;
      }
    }

    .placeOrder {
      width: 100%;
      margin-top: 5rem;
      text-align: center;

      p,
      a {
        ${bodyCopy};
        margin-bottom: 5rem;
      }

      a {
        &:hover {
          color: ${colors.colorTertiary};
        }
      }

      button {
        ${buttonOne};
      }
    }
  }
`

export default CheckoutForm
