import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { RatesContext } from "../../context/RatesContext"
import { UiContext } from "../../context/UiContext"
import { bodyCopy, colors, fontSizer } from "../../Utilities"
import axios from "axios"
import { updateShippingCosts } from "../../Utilities/cart/rates"

import {
  cartSubtotal,
  cartTotalTax,
  cartTotal as getCartTotal,
} from "../../Utilities/cart/cart"
import { formatPrice } from "../../Utilities/cart/format"

const CartTotalTable = ({ cart, curpage }) => {
  const [ratesState, rateDispatch] = useContext(RatesContext)
  const { dispatch: uiDispatch } = useContext(UiContext)

  const checkShippingCost = async () => {
    uiDispatch({ type: "SERVER_LOAD" })
    try {
      const reponse = await axios.get(
        `${process.env.GATSBY_API_URL}/shop-rates`
      )
      const freeShippingThreshold = reponse.data.free_shipping_threshold * 100
      const cartSubTotalInCents = cartSubtotal(cart) - ratesState.giftCard
      const shippingRate = updateShippingCosts(
        ratesState.activeLocation,
        ratesState.activeProvince,
        reponse.data,
        cartSubTotalInCents
      )

      const SHIPPING_REQUIRED = cart.some((item) => item.should_charge_shipping)

      rateDispatch({
        type: "RATE_CHANGE",
        payload: {
          activeShipping: !SHIPPING_REQUIRED ? 0 : shippingRate,
          freeShipping: !SHIPPING_REQUIRED
            ? true
            : cartSubTotalInCents >= freeShippingThreshold,
        },
      })

      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
    } catch (err) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({
        type: "ALERT_ERROR",
        payload: `${err.message}, Please try again later.`,
      })
    }
  }

  useEffect(() => {
    checkShippingCost()
  }, [])

  useEffect(() => {
    checkShippingCost()
  }, [ratesState.giftCard])

  const shippingCostToUse =
    cart && cart.length > 0 ? ratesState.activeShipping : 0

  const mainSubTotal = cartSubtotal(cart)

  return (
    <CartTotalTableStyled curpage={curpage}>
      <div className="totalHead">
        <p>Cart Totals</p>
      </div>
      <div className="totalBody">
        <div className="totalBody__item subTotal">
          <div className="totalBody__item--title">
            <p>Subtotal</p>
          </div>
          <div className="totalBody__item--info">
            {cart.length <= 0 ? (
              <p>$0.00</p>
            ) : (
              <p>{formatPrice(mainSubTotal)}</p>
            )}
          </div>
        </div>
        {ratesState.giftCard > 0 ? (
          <div className="totalBody__item subTotal">
            <div className="totalBody__item--title">
              <p>Gift Card</p>
              <p>{ratesState.giftCardId}</p>
              <p>Card Value: {formatPrice(ratesState.giftCard)}</p>
            </div>
            <div className="totalBody__item--info">
              <p>
                &#8722;
                {ratesState.giftCard >= mainSubTotal
                  ? formatPrice(mainSubTotal)
                  : formatPrice(ratesState.giftCard)}
              </p>
            </div>
          </div>
        ) : null}

        <div className="totalBody__item subTaxes">
          <div className="totalBody__item--title">
            <p>Taxes</p>
          </div>
          <div className="totalBody__item--info">
            <p>
              {formatPrice(
                cartTotalTax(
                  mainSubTotal,
                  ratesState.activeTax,
                  ratesState.giftCard
                )
              )}
            </p>
          </div>
        </div>

        <div className="totalBody__item shipping">
          <div className="totalBody__item--title">
            <p>{curpage !== "checkout" && "estimated Flat Rate "}Shipping</p>
            {ratesState.freeShipping ? null : <p>Confirmed on checkout form</p>}
            {!ratesState.freeShipping ? null : <p>Free Canada Shipping</p>}
          </div>
          <div className="totalBody__item--info">
            <p>{formatPrice(shippingCostToUse)}</p>
          </div>
        </div>

        <div className="totalBody__item total">
          <div className="totalBody__item--title">
            <p>Total</p>
          </div>
          <div className="totalBody__item--info">
            <p>
              {formatPrice(
                getCartTotal(
                  cart,
                  shippingCostToUse,
                  ratesState.activeTax,
                  ratesState.giftCard
                )
              )}
            </p>
          </div>
        </div>
      </div>
    </CartTotalTableStyled>
  )
}

const CartTotalTableStyled = styled.div`
  width: 100%;
  border: solid 0.1rem ${colors.black};
  margin-top: 5rem;

  @media (min-width: 768px) {
    width: ${(props) => (props.curpage ? "75rem" : "50%")};
    margin-left: ${(props) => (props.curpage ? "0%" : "50%")};
    margin-top: ${(props) => (props.curpage ? "5rem" : "10rem")};
  }

  .totalHead {
    width: 100%;
    padding: 0.25rem 1rem;

    p {
      ${bodyCopy};
      margin: 0;
      text-transform: uppercase;
    }
  }

  .totalBody {
    width: 100%;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100%);
      padding: 2rem 0.5rem;
      border-top: solid 0.1rem ${colors.black};

      & > div {
        padding: 0.5rem;
      }

      &--title {
        width: calc(55%);
        margin-left: 20%;
        text-align: right;

        p {
          text-align: right !important;
        }

        a {
          ${bodyCopy};
          ${fontSizer(1.2, 1.4, 76.8, 150, 1.6)};
          margin: 0;

          &:hover {
            color: ${colors.colorAccent};
          }
        }
      }

      &--info {
        width: calc(25%);
      }

      &.total {
        background-color: ${colors.colorAccent};
      }

      p {
        ${bodyCopy};
        ${fontSizer(1.2, 1.4, 76.8, 150, 1.6)};
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
`

export default CartTotalTable
