import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { RatesContext } from "../../context/RatesContext"
import { UiContext } from "../../context/UiContext"
import axios from "axios"
import { fontSizer, colors, buttonOne } from "../../Utilities"

const GiftCard = () => {
  const [ratesState, rateDispatch] = useContext(RatesContext)
  const { dispatch: uiDispatch } = useContext(UiContext)
  const [showGiftCard, setShowGiftCard] = useState(false)
  const [giftCardId, setGiftCardId] = useState("")

  useEffect(() => {
    if (ratesState.giftCardId !== "") {
      setShowGiftCard(true)
      setGiftCardId(ratesState.giftCardId)
    }
  }, [])

  const handleToggleGiftCard = () => {
    setShowGiftCard(!showGiftCard)
  }

  const handleClearGiftCard = () => {
    rateDispatch({
      type: "GIFTCARD_CHANGE",
      payload: { balance: 0, cardID: "" },
    })
    setGiftCardId("")
  }

  const handleOnChange = (event) => {
    setGiftCardId(event.target.value)
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    if (giftCardId === "") return
    uiDispatch({ type: "SERVER_LOAD" })

    try {
      const reponse = await axios.get(
        `${process.env.GATSBY_API_URL}/gift-cards/${giftCardId}`
      )
      const balance = reponse.data.gift_card_balance * 100
      const cardID = reponse.data.gift_card_id
      rateDispatch({ type: "GIFTCARD_CHANGE", payload: { balance, cardID } })
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({ type: "ALERT_ERROR", payload: "Gift Card Applied" })
    } catch (err) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({ type: "ALERT_ERROR", payload: err.response.data.message })
    }
  }

  return (
    <GiftCardStyled>
      <div>
        <div className="giftCardActive">
          <button
            onClick={() => {
              if (showGiftCard) handleClearGiftCard()
              handleToggleGiftCard()
            }}
          >
            {!showGiftCard ? "Enter Gift Card" : "clear"}
          </button>
        </div>
        {showGiftCard && (
          <div>
            <div className="inputFields">
              <label>Gift Card Number</label>
              <input
                type="text"
                onChange={(event) => handleOnChange(event)}
                value={giftCardId}
              />
            </div>
            <div className="inputFields__submit">
              <button
                disabled={giftCardId === ""}
                type="button"
                onClick={(event) => handleOnSubmit(event)}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </GiftCardStyled>
  )
}

const GiftCardStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;

  @media (min-width: 768px) {
    width: calc(100% - 1.5rem);
  }

  button {
    ${buttonOne};
  }

  .giftCardActive {
    width: 100%;
    text-align: right;
  }

  .inputFields {
    margin-top: 1rem;
    margin-bottom: 3.5rem;

    &__submit {
      width: 100%;
      text-align: right;
    }
  }

  label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: block;
    width: 100%;
    color: ${colors.black};

    span {
      ${fontSizer(1, 1.4, 76.8, 150, 0.8)};
    }
  }

  .errorMessage {
    ${fontSizer(1.2, 1.4, 76.8, 150, 1.2)};
    position: absolute;
    top: 1.5rem;
    left: 0;
    margin: 0;
    color: ${colors.strongRed};

    @media (min-width: 768px) {
      top: 1rem;
    }
  }

  input {
    width: 100%;
    padding: 1rem 2rem;
    border: 0.2rem solid rgba(0, 0, 0, 0.75);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: ${colors.white};

    &::placeholder {
      color: ${colors.colorAccent};
    }
  }
`

export default GiftCard
