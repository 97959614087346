import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { colors, fontSizer } from "../../Utilities"

const DropDown = ({
  label,
  value,
  name,
  onChange,
  options = [],
  size,
  fieldvalid,
  required,
}) => {
  const [currentFieldVal, setCurrentFieldVal] = useState({
    fieldName: name,
    valid: true,
    message: "",
  })
  useEffect(() => {
    const currentField = fieldvalid.find(field => field.fieldName === name)
    if (currentField) {
      setCurrentFieldVal({ ...currentField })
    }
  }, [fieldvalid])
  const handleOnFocus = () => {
    setCurrentFieldVal({
      fieldName: name,
      valid: true,
      message: "",
    })
  }
  const handleOnBlur = () => {
    const currentField = fieldvalid.find(field => field.fieldName === name)
    if (currentField && currentFieldVal.valid !== currentField.valid) {
      setCurrentFieldVal({ ...currentField })
    }
  }
  return (
    <DropDownStyled size={size}>
      <label name={name}>
        {label}
        {required && (
          <>
            &#42;<span> required field</span>
          </>
        )}
      </label>
      {!currentFieldVal.valid && (
        <p className="errorMessage">{currentFieldVal.message}</p>
      )}
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        required={required}
      >
        <option title="select an option" value="">
          {" "}
          -- select an option --{" "}
        </option>
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          )
        })}
      </select>
    </DropDownStyled>
  )
}

const DropDownStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 3rem;

  @media (min-width: 768px) {
    width: ${props =>
      props.size === "full" ? " calc(100% - 1.5rem)" : " calc(50% - 1.5rem)"};
  }

  label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: block;
    width: 100%;
    color: ${colors.black};
  }

  .errorMessage {
    ${fontSizer(1.2, 1.4, 76.8, 150, 1.2)};
    position: absolute;
    top: 4rem;
    right: 0;
    margin: 0;
    color: ${colors.strongRed};

    @media (min-width: 768px) {
      top: 3rem;
    }
  }

  select {
    width: 100%;
    padding: 1rem 2rem;
    border: 0.2rem solid rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: ${colors.black};
  }
`

export default DropDown
