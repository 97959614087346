export const nameValidation = (fieldName, fieldValue) => {
  if (fieldValue.trim() === "") {
    return `${fieldName} is required`
  }
  if (/[^a-zA-Z -]/.test(fieldValue)) {
    return "Invalid characters"
  }
  if (fieldValue.trim().length < 2) {
    return `${fieldName} needs to be at least three characters`
  }
  return true
}

export const validText = (fieldName, fieldValue) => {
  if (fieldValue.trim() === "") {
    return `${fieldName} is required`
  }
  return true
}

export const validateEmail = email => {
  if (email.trim() === "") {
    return "Email is required"
  }
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(email)) {
    return "Email is not valid"
  }

  return re.test(email)
}

export const validateEmailMatching = (emailOne, emailTwo) => {
  const isMatch = emailOne.toLowerCase() === emailTwo.toLowerCase()
  if (!isMatch) {
    return "Emails do not match"
  }
  return isMatch
}

export const ageValidation = age => {
  if (!age) {
    return "Age is required"
  }
  if (age < 18) {
    return "Age must be at least 18"
  }
  if (age > 99) {
    return "Age must be under 99"
  }
  return null
}

export const validateNameField = (formStateCopy, fieldName, fieldValue) => {
  const validField = nameValidation(fieldName, fieldValue)
  const fieldIndex = formStateCopy.findIndex(
    field => field.fieldName === fieldName
  )
  if (fieldIndex !== -1 && validField !== true) {
    formStateCopy[fieldIndex] = {
      fieldName: fieldName,
      valid: false,
      message: validField,
    }
  }
  return formStateCopy
}

export const validateTextField = (formStateCopy, fieldName, fieldValue) => {
  const validField = validText(fieldName, fieldValue)

  const fieldIndex = formStateCopy.findIndex(
    field => field.fieldName === fieldName
  )
  if (fieldIndex !== -1 && validField !== true) {
    formStateCopy[fieldIndex] = {
      fieldName: fieldName,
      valid: false,
      message: validField,
    }
  }
  return formStateCopy
}

export const validateEmailField = (formStateCopy, fieldName, fieldValue) => {
  const validField = validateEmail(fieldValue)

  const fieldIndex = formStateCopy.findIndex(
    field => field.fieldName === fieldName
  )
  if (fieldIndex !== -1 && validField !== true) {
    formStateCopy[fieldIndex] = {
      fieldName: fieldName,
      valid: false,
      message: validField,
    }
  }
  return formStateCopy
}

export const validateEmailMatchField = (
  formStateCopy,
  fieldName,
  fieldValue,
  fieldMatchValue
) => {
  const validField = validateEmailMatching(fieldValue, fieldMatchValue)
  const fieldIndex = formStateCopy.findIndex(
    field => field.fieldName === fieldName
  )
  if (fieldIndex !== -1 && validField !== true) {
    formStateCopy[fieldIndex] = {
      fieldName: fieldName,
      valid: false,
      message: validField,
    }
  }
  return formStateCopy
}
